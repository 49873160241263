<template>
  <div class="forecast-container">
    <iframe
      width="100%"
      height="400"
      :src="embedLink()"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  props: {
    latitude: {
      type: String,
      default: '-7.69'
    },
    longitude: {
      type: String,
      default: '108.655'
    }
  },
  methods: {
    embedLink () {
      const type = 'type=map'
      const location = 'location=coordinates'
      const metricRain = 'metricRain=mm'
      const metricTemp = 'metricTemp=°C'
      const metricWind = 'metricWind=km/h'
      const zoom = 'zoom=9'
      const overlay = 'overlay=satellite'
      const product = 'product=ecmwf'
      const level = 'level=surface'
      const lat = `lat=${this.latitude}&detailLat=${this.latitude}`
      const long = `lon=${this.longitude}&detailLon=${this.longitude}`
      const marker = 'marker=true'

      const params = `?${type}&${location}&${metricRain}&${metricTemp}&${metricWind}&${zoom}&${overlay}&${product}&${level}&${lat}&${long}&${marker}`

      const link = `https://embed.windy.com/embed.html${params}`
      return link
    }
  }
}
</script>

<style lang="scss">
  .forecast-container {
    iframe {
      border-radius: 20px;
    }
  }
</style>
